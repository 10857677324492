import { getArray } from '@dte/otw/azure-functions/src/common/util/lists/mergeArrays';

// Features within the app
export enum AppAction {
	SUMMARY = 'summary',
	TIMELINE = 'timeline',
	SEARCH_ORDERS = 'orders',

	REPORT_PROBLEM = 'reportProblem',
	ADD_CALL_COMMENT = 'addCallComment',

	SWITCH_MODE = 'switchMode',
	EDIT_MODES = 'editModes',
	EDIT_COUNTS = 'editCounts',
	EDIT_SETTINGS = 'editSettings',

	// Any config action
	CONFIG = 'config',

	DEBUG = 'debug',
}

// Roles that are allowed to access each feature
const AllowedRoles: Record<string, string[]> = {};
AllowedRoles[AppAction.SUMMARY] = ['otw.admin', 'summary.read'];
AllowedRoles[AppAction.TIMELINE] = ['otw.admin', 'history.read'];

AllowedRoles[AppAction.REPORT_PROBLEM] = ['otw.admin', 'orders.write'];
AllowedRoles[AppAction.SEARCH_ORDERS] = ['otw.admin', 'orders.write', 'orders.read'];
AllowedRoles[AppAction.ADD_CALL_COMMENT] = ['otw.admin', 'orders.write'];

AllowedRoles[AppAction.SWITCH_MODE] = ['otw.admin', 'config.write', 'modes.write'];
AllowedRoles[AppAction.EDIT_MODES] = ['otw.admin', 'config.write', 'messages.write'];
AllowedRoles[AppAction.EDIT_COUNTS] = ['otw.admin', 'counts.write'];
AllowedRoles[AppAction.EDIT_SETTINGS] = ['otw.admin'];

// Can access config if you can do any of the other editing type activities
AllowedRoles[AppAction.CONFIG] = [
	...AllowedRoles[AppAction.SWITCH_MODE],
	...AllowedRoles[AppAction.EDIT_MODES],
	...AllowedRoles[AppAction.EDIT_COUNTS],
	...AllowedRoles[AppAction.EDIT_SETTINGS],
];

AllowedRoles[AppAction.DEBUG] = ['otw.admin', 'history.read'];

function hasRole(userRoles: string[], allowedRoles: string[]): boolean {
	const roles = getArray(userRoles);

	for (const role of allowedRoles) {
		if (roles.includes(role)) {
			return true;
		}
	}

	return false;
}

export function isAllowed(action: AppAction, roles: string[]): boolean {
	// Roles not defined yet
	if (roles === undefined) {
		return undefined;
	}

	// Assume true if no action is passed in
	if (!action) {
		return true;
	}

	return hasRole(roles, AllowedRoles[action]);
}
